.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(169, 169, 169, 0.25);
    height: 240px;
    width: 200px;
    position: fixed;
    left: 0;
    top: calc(50% - 120px); /* Center the sidebar vertically */
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  .sidebar-button {
    margin: 10px 0;
    padding: 10px; /* Add padding */
    height: 50px; /* Set the height of the buttons */
    width: 100%;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    font-family: 'Newsreader', serif; /* Add the desired font-family */
    font-size: 25px; /* Set the font size */
    border-radius: 0px;
  }
  
  .sidebar-button.active {
    background-color: rgba(169, 169, 169, 0.25);
    border-radius: 12px; /* Add rounded corners to the active button */
  }  