.faq-section {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.faq-title h1 {
    font-size: 3em;
    margin-bottom: -40px; /* Decreased from 50px to 10px */
}

.faq-title h2 {
    font-size: 3.5em;
    margin-bottom: 50px;
}

.faq-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.faq-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
}

.faq-card {
    background-color: rgba(217, 217, 217, 0.25);
    border-radius: 25px;
    width: 600px; 
    max-width: 48%;
    padding: 20px;
    box-sizing: border-box;
}

.faq-card h3 {
    font-size: 2em;
    margin-bottom: 15px;
}

.faq-card p {
    font-size: 1.3em;
}

@media (max-width: 600px) {
  .faq-cards, .faq-row {
    flex-direction: column;
    align-items: center;
  }

  .faq-row {
    margin-bottom: 0px;
}


  .faq-card {
    margin-left: -25%;
    width: 150%;
    max-width: 150%;
    margin-top: 10px;
  }
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}
  
@keyframes slideInFromRight {
    0% {
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}
  
.animate-left {
    animation: slideInFromLeft 1s forwards;
    animation-delay: 500ms;
}
  
.animate-right {
    animation: slideInFromRight 1s forwards;
    animation-delay: 500ms;
}