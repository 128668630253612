.main-content {
    background-color: rgba(217, 217, 217, 0.25);
    border-radius: 20px;
    width: calc(98vw - 300px);
    height: calc(100vh - 200px);
    margin: 130px 100px 0 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white; /* added to make text white */
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.button {
    background-color: #007D76;
    border-radius: 8px;
    color: white;
    padding: 10px;
    cursor: pointer;
}

.button.active {
    background-color: #005a50; /* darker color for active button */
}

.inner-content {
    background: none; /* removes background */
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    width: 100%; /* full width */
    height: 100%; /* full height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-content.upload,
.inner-content.text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

textarea {
    width: 100%; /* full width */
    height: 100%; /* full height */
    color: white; /* makes text white */
    background: none; /* removes background */
    resize: none; /* disables resizing */
    border: none; /* removes border */
}

.detect-now {
    margin-top: 20px;
}

.output-text {
    color: white; /* makes output text white */
    margin-top: 20px;
}