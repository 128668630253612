.plans-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin: 0 15vw 0;
}

.plan-card {
    background-color: rgba(169, 169, 169, 0.25);
    border: 5px solid #007D76;
    border-radius: 25px;
    padding: 20px;
    width: calc(30% - 30px);
    height: 450px; 
    margin-bottom: 30px;
    text-align: left;
    opacity: 0;
}

.plan-card-large {
    height: 500px; /* Made the large card taller */
}

.plan-title, .plan-price, .plan-feature {
    color: #FFFFFF;
}

.plan-title {
    font-size: 1.75em;
    margin-bottom: -30px; /* Removed the space between "Free Trial" and "$0/mo" */
}

.plan-price {
    font-size: 3em;
    margin-bottom: 20px; /* Added some space between "$0/mo" and the features */
}

.plan-features {
    font-size: 1.5em;
    padding-left: 20px;
    margin-bottom: 20px;
}

.plan-feature {
    margin-bottom: 15px; /* Added more space between each feature */
}

.plan-feature:before {
    content: "\2022";
    color: #007D76;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.plan-description {
    color: #FFFFFF;
}

.plans-title {
    text-align: center;
    color: #FFFFFF;
    font-size: 3em;
    margin: 150px 0 50px;
}

@media screen and (max-width: 768px) {
    .plans-container {
        flex-direction: column;
        align-items: center;
    }

    .plan-card {
        width: 95%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.plan-card.animate:nth-child(1) {
    animation: enterFromLeft 1s forwards;
}

.plan-card.animate:nth-child(3) {
    animation: enterFromRight 1s forwards;
}

.plan-card.animate:nth-child(2) {
    animation: enterFromBottom 1s forwards;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-left {
    animation: slideInFromLeft 1s forwards;
    animation-delay: 500ms;
  }
  
  .animate-right {
    animation: slideInFromRight 1s forwards;
    animation-delay: 500ms;
  }
  
  .animate-up {
    animation: slideInFromBottom 1s forwards;
    animation-delay: 100ms;
  }
  