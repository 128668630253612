.login-page {
    display: flex;
    justify-content: space-between;
    height: 100vh; /* This will make the login page take the full height of the viewport */
}

.login-image {
    width: 50%;
}

.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* This will ensure the form takes the full height of its parent */
}

.login-form h1 { /* New rule for the "Login" heading */
    text-align: left;
    width: 80%; /* Same width as the input boxes */
    color: white; /* Make the text white */
}

.login-form input,
.login-form button {
    margin: 10px 0;
    padding: 10px;
    width: 80%;
}

.login-form button {
    margin: 10px 0;
    padding: 10px;
    width: 80%;
    background-color: #007D76;
    border: none;
    border-radius: 8px;
    color: white;
}

.login-form p {
    margin-top: 20px;
    color: white; /* Make the paragraph text white */
}

.login-form a {
    color: #007D76;
    text-decoration: none;
}

.login-form a:hover {
    text-decoration: underline;
}

h2 {
    color: white;
    align-content: left;
}