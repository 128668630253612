/* The ".about-section" class styles the whole About section. It makes the section take the full width of the page and centers its content. */
.about-section {
  width: 100%; /* Make the section take the full width of its container */
  padding: 50px 0; /* Add padding at the top and bottom of the section */
  display: flex; /* Use Flexbox to align the elements within */
  flex-direction: column; /* Arrange the child elements in a column */
  align-items: center; /* Center the child elements horizontally */
  justify-content: center; /* Center the child elements vertically */
  text-align: center; /* Center the text within the child elements */
}

/* The ".about-title" class styles the title of the About section. */
.about-title {
  font-size: 5vw; /* The font size will be 5% of the viewport width */
  color: #FFFFFF; /* The text color will be white */
  font-family: 'Newsreader', serif; /* The font used will be 'Newsreader' */
  font-weight: 400; /* The font weight is set to normal */
}

/* The ".about-text" class styles the text of the About section. */
.about-text {
  font-size: 2vw; /* The font size will be 2% of the viewport width */
  color: #FFFFFF; /* The text color will be white */
  font-weight: 400; /* The font weight is set to normal */
  font-family: 'Newsreader', serif; /* The font used will be 'Newsreader' */
  margin: 0 15vw; /* Add some space on the left and right of the text */
  text-align: justify; /* Justify the alignment of the text */
  text-indent: 2em; /* Add a tab indent at the start of the first paragraph */
}

/* This is a media query. It will apply the contained styles only if the width of the viewport is 600px or less. This is typically used for adapting the design to smaller screens, such as mobile devices. */
@media (max-width: 600px) {
  .about-title {
      font-size: 8vw; /* On small screens, make the font size of the title bigger to stand out more */
      width: 50%; /* Limit the width of the title to 50% of its container */
  }

  .about-text {
      font-size: 4.5vw; /* On small screens, make the font size of the text bigger for better readability */
  }
}