@import url('https://fonts.googleapis.com/css2?family=Newsreader:wght@400;700&display=swap');

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  background-color: transparent;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.logo {
  font-size: 1.5em;
  margin-right: 100px;
  color: #FFFFFF;
  text-decoration: none; /* Add this to remove the underline from the link */
}


.logo a {
  text-decoration: none;
  font-size: 1.5em;
  margin-right: 100px;
  color: #FFFFFF;
}

.links-wrapper {
  margin-left: auto;
  margin-right: 100px;
}

.nav-links {
  display: flex;
  gap: 85px;
  padding: 0;
  margin: 0;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  color: #FFFFFF;
  text-decoration: none;
}

.link-button {
  padding: 10px 15px;
  background-color: #007D76;
  color: #FFFFFF;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-right: 30px;
}

.link-button:hover {
  background-color: #005952;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0 0 8px 8px;
    padding: 10px 0;
  }

  .nav-links.visible {
    display: flex;
  }

  .dropdown-button {
    display: block;
  }
}

.dropdown-button {
  display: none;
  margin-left: auto;
  padding: 10px 15px;
  background-color: #007D76;
  color: #FFFFFF;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.dropdown-button:hover {
  background-color: #005952;
}
