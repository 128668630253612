.footer {
    background-color: rgba(255, 255, 255, 0.25);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    height: 40vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    padding: 0 5vw;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer-text {
    padding-left: -25px;
    width: 500px;
    text-align: left;
}

.footer-content h1 {
    font-size: 2em;
}

.footer-content p {
    font-size: 1.5em;
    
}

.footer-links {
    display: flex;
    justify-content: space-between;
}

.footer-links h3 {
    font-size: 1.75em;
}

.footer-links ul {
    padding: 0;
    list-style-type: none;
}
.footer-links div {
    margin-left: 30px;
}

.footer a {
    color: white;
    text-decoration: underline;
  }
  
  .footer a:hover {
    color: white;
  }
  
  .footer a:visited {
    color: white;
  }
  


/* Add this media query for mobile devices */
@media screen and (max-width: 768px) {
    .footer {
        height: 120vh; /* Adjust this to your liking */
    }

    .footer-content {
        flex-direction: column;
    }

    .footer-text {
        width: 95%;
        margin: 0 auto; /* Center the text block */
    }

    .footer-links {
        flex-direction: column;
    }
}