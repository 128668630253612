.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120vh; /* This will center vertically, adjust as needed */
    padding-left: 10%;  /* This will add space to the left of the text */
    padding-right: 10%;  /* This will add space to the right of the text */  
}
  
.paragraph {
    text-indent: 2em;  /* This will add a tab (2em space) before the start of each paragraph */
    margin-bottom: 1em; /* This will add space between paragraphs */
    color: white; 
    font-size: 1vw; /* The font size will be 5% of the viewport width */
}

.team-title {
    font-size: 4vw; /* The font size will be 5% of the viewport width */
    color: #FFFFFF; /* The text color will be white */
    font-family: 'Newsreader', serif; /* The font used will be 'Newsreader' */
    font-weight: 400; /* The font weight is set to normal */
}