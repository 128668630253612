.hero-section {
  position: absolute;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
  text-align: center;
}

.hero-section h1 {
  font-size: 4vw;
  color: #FFFFFF;
  margin-bottom: 20px;
  font-family: 'Newsreader', serif;
  font-weight: 400;
  text-align: center;
}

.hero-section span {
  display: block;
}

.hero-button {
  padding: 15px 30px;
  width: 13%;
  height: 50px;
  background-color: #007D76;
  color: #FFFFFF;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-family: 'Newsreader', serif;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 50px;
  text-align: center;
}

.hero-button:hover {
  background-color: #005D57;
}  

/* Add this media query for mobile devices */
@media screen and (max-width: 768px) {
  .hero-button {
      width: 50%;
      font-size: 2em;
  }

  .hero-section h1 {
      font-size: 12vw;
  }
}