.explainer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.explainer-title {
  font-size: 5vw;
  color: #FFFFFF;
  font-family: 'Newsreader', serif;
  font-weight: 400;
}

.explainer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 50vh;
  margin: 50px auto 0; /* added 50px margin-top */
}

.svg-container,
.text-container,
.box-container {
  flex: 1;
  width: 33.33%;
  height: 66.67vh;
}

.box-container p {
  font-size: 1vw;
}

.svg-container {
  /* SVG styles go here */
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 30px;
}

.text-container p {
  font-size: 1.4vw;
  color: #FFFFFF;
  font-family: 'Newsreader', serif;
}

.box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(217, 217, 217, 0.25);
  border-radius: 25px;
  padding: 20px;
  color: #FFFFFF;
  font-family: 'Newsreader', serif;
}  

.svg-container img {
  max-width: 100%;
  max-height: 100%;
}

/* Media queries for mobile layout */
@media (max-width: 600px) {
  .explainer-container {
    flex-direction: column;
    width: 85%;
    height: auto;
  }

  .svg-container,
  .text-container,
  .box-container {
    width: 100%;
    height: auto;
  }

  .explainer-title {
    width: 75%;
    margin: 0 auto;
  }

  .text-container p {
    text-align: center;
  }

  .svg-container img {
    display: block;
    margin: 0 auto;
  }
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}
  
@keyframes slideInFromRight {    0% {
  transform: translateX(50px);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
}

@keyframes slideInFromBottom {
0% {
  transform: translateY(50px);
  opacity: 0;
}
100% {
  transform: translateY(0);
  opacity: 1;
}
}

.animate-left {
animation: slideInFromLeft 1s forwards;
animation-delay: 500ms;
}

.animate-right {
animation: slideInFromRight 1s forwards;
animation-delay: 500ms;
}

.animate-up {
animation: slideInFromBottom 1s forwards;
animation-delay: 1000ms;
}